.cake-flavors-content {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #4A4A4A;
  background-color: #FAFAFA;
  line-height: 1.6;
}

.size-img-cake {
  width: 100%;
  height: auto;
  object-fit: cover; 
}

.cardshape-cake {
  width: 100%;
  max-width: 40rem;
  margin: auto;
}

.carousel-cake {
  background-color: #e6a1c3;
  border: 1px solid #e6a1c3; 
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.top-text {
  font-family: 'Arial', sans-serif;
  color: #4A4A4A;
  background-color: #FFE;
  padding: 20px;
  border-bottom: 3px solid #D3BCC0;
  margin-bottom: 20px;
  text-align: center;
}

.top-text h1 {
  font-family: 'Arial', sans-serif;
  color: #D45D79;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.top-text p {
  font-size: 1rem;
  color: #333;
  padding: 0 20px;
}

@media (max-width: 991px) {
  .size-img-cake,
  .cardshape-cake {
    object-fit: contain;
    width: 100%;
    margin: 20px auto;
  }
}

@media (max-width: 767px) {
  .size-img-cake,
  .cardshape-cake,
  .top-text, 
  .about-me-container, 
  .carousel-cake,
  .carousel-wrapper {
    width: 100%;
    margin: 10px auto;
    text-align: center;
  }

  .top-text h1 {
    font-size: 2rem; 
  }
  .size-img-cake {
    width: 100%; 
    height: auto; 
    object-fit: cover; 
  }
}

.carousel-wrapper {
  max-width: 960px; 
  margin: auto;
  padding: 20px; 
}
