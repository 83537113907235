
.adminReservationTable-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .adminReservationTable-th, .adminReservationTable-td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .adminReservationTable-th {
    background-color: #f2f2f2;
  }
  
  .adminReservationTable-tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .adminReservationTable-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .adminReservationTable-button:hover {
    opacity: 0.8;
  }
  