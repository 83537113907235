 .cake-flavors-content {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #4A4A4A; 
    background-color: #FAFAFA; 
    line-height: 1.6;
  }
  
  .cake-flavors-content > div {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .cake-flavors-content .cake-header {
    color: #E63946;
    padding: 15px;
}

  

  .cake-flavors-content h2 {
    color: #F4A261; 
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;

  }
  
  .cake-flavors-content h3 {
    color: #F4A261; 
    margin-top: 1.5rem;
  }
  
  .cake-flavors-content ul {
    list-style-type: none; 
    padding-left: 0;
  }
  
  .cake-flavors-content li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
  }
  
  .cake-flavors-content li::before {
    content: "•"; 
    color: #15c2ae; 
    font-size: 1.1em; 
    position: absolute;
    left: 0;
  }
  
  .cake-flavors-content .round-heart-cakes, 
  .cake-flavors-content .tiered-cakes, 
  .cake-flavors-content .number-cakes, 
  .cake-flavors-content .letter-cake,
  .cake-flavors-content .cake-flavors, 
  .cake-flavors-content .fillings {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); 
    gap: 15px;
    margin-top: 20px;
  }
  

  .cake-flavors-content .cake-flavors li, 
  .cake-flavors-content .fillings li {
    padding-left: 10px;
  }

  @media (max-width: 768px) {
    .cake-flavors-content > div {
      margin: 10px;
      padding: 10px;
    }
  }
  