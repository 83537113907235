.calendar-note {
    font-size: 0.9em !important;
    color: #333333 !important; 
    margin-top: 10px !important;
    font-weight: bold;
  }

  .blockedDate {
    background: grey;
    color: white  !important; 
  }
  
  .highlight, .reservedDate, .reserved-date {
    background-color: #D50000; 
    color: white !important; 
  }

  .date-info {
    font-family: 'Arial', sans-serif;
    color: #FFD700; 
    text-align: center;
    margin-bottom: 30px;
  }

  .contact-info h2, .date-info {
    color: #F4A261;
    border-bottom: 2px solid #e62b2b; 
  }
  
  .content-body p {
    color: #312829fd; 
  }
  
  .contact-calendar {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #FFFDF7;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-info, .social-media {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .calendar-legend {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .calendar-legend .legend-color {
    background-color: #86C67C;
  }
  
  .legend-color {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: #86C67C; 
  }
  
  .cake-flavors-content {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #4A4A4A; 
    background-color: #FAFAFA; 
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .contact-and-calendar {
      margin: 10px;
      padding: 10px;
    }
  }
  