.footer {
    background-color: #e6a1c3; 
    padding: 20px;
    color: #fff; 
    text-align: center;
  }
  

  .footer h1, .footer p {
    color: #fff; 
  }
  

  .footer a {
    color: #fff;
    text-decoration: none; 
  }
  
  .footer a:hover {
    color: #ffd700; 
  }
  