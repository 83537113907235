body {
  margin: 0;
  padding: 40px 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #FCEFF9; 
}

.faq-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #FFF8E7; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  background-color: #FFD6E7; 
  color: #333333; 
  padding: 15px 20px;
  margin: 10px 0;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 4px;
  border: none;
}

.faq-question:hover {
  background-color: #FFB6C1; 
  color: #FFFFFF; 
}

.faq-answer {
  background-color: #FFF8E7;
  color: #333333; 
  padding: 15px;
  margin-top: -10px;
  font-size: 16px;
  border-top: 2px solid #FFD700;
  border-radius: 0 0 4px 4px;
}

.faq-container h1 {
  font-family: 'Arial', sans-serif;
  color: #F4A261;
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #e62b2b; 
}

@media (max-width: 768px) {
  .faq-container {
    margin: 20px;
    padding: 10px;
  }

  .faq-question, .faq-answer {
    padding: 10px;
    font-size: 18px;
  }
}


html, body {
  margin: 0;
  padding: 0;
}
