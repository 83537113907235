
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1&display=swap');

body {
    font-family: 'Roboto', sans-serif;
  }

  .navbar-brand, .nav-link, .navbar-toggler, .nav-dropdown-item {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    padding: 10px 15px;
    font-size: 1.2rem;
  }
.navbar-top-text {
    align-self: center;
    text-align: center;
    font-size: 2.5rem;
    color: #1d1c1c; 
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    color: #ffffff; 
    font-weight: bold;
  }

.nav-link {
    font-size: 1.4rem; 
  }
  
  .navbar-brand, .nav-link {
    padding: 20px 15px; 
    font-size: 1.4rem;
  }
  
  .navbar-toggler {
    padding: .7rem 1rem;
    font-size: 1.2rem; 
  }
  

  .nav-dropdown-item {
    font-size: 1rem;
  }

  .navbar-top-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #c20a0a;
    padding: 10px;
  }

  .navbar-teal .navbar-brand,
  .navbar-teal .nav-link {
      color: #ffffff; 
  }
  
  .navbar-teal .nav-link:hover {
      color: #cccccc; 
  }
  .navbar-teal {
    background-color: #e6a1c3; 
}


.navbar-top-text p {
  font-size: 0.4em; 
  margin-bottom: 10px; 
  line-height: 1.2; 
  color:  #333333
  
}
