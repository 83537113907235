
.content-area{
  max-width: 800px; 
  margin: 40px auto;
  padding: 20px; 
  background-color: #ffffff; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.938); 
  border-radius: 8px;
  line-height: 1.6;
}

.content-area h1, 
.content-area h2 {
  color: #F4A261;
  text-align: center; 
  border-bottom: 2px solid #e62b2b; 
  padding-bottom: 10px; 
}
  
.content-area h1 {
  margin-top: 0; 
  color: #F4A261;
}

.content-area ul {
  list-style-type: none; 
  padding: 0;
}

.content-area li {
  margin-bottom: 10px; 
}

.payment-detail {
  font-size: 18px; 
  color: #333; 
}

.payment-method {
  font-weight: bold; 
}


.payment-note {
  text-align: center; 
  font-style: italic; 
  margin-top: 20px; 
}