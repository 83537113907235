.about-me-container {
  background-color: #ffffff;
  padding: 20px;
  margin: 20px auto; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 20px;
  max-width: 800px;
}

  .about-me-container h1 {
    margin-bottom: 1.3rem;
    font-style: italic;
    text-align: center;
    color: #F4A261; 
    border-bottom: 2px solid #e62b2b; 
  }

  .about-me-container p {
    margin-bottom: 1rem;
    text-align: justify;
    font-style: italic;
    font-size: 1.05rem;
  }


body { 
  background-color: #e6a1c3; 
  font-family: 'Roboto', sans-serif;
}